import React from 'react'
import { Helmet } from 'react-helmet'
import { Content } from 'components/section/portal_guide/content'

// interface PortalGuidePageProps {}

const PortalGuidePage = (): JSX.Element => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          class: 'portal-guide-html',
        }}
        bodyAttributes={{
          class: 'portal-guide-body',
        }}
      >
        <title>ポータルサイト ログイン方法</title>
        <meta
          name="description"
          content="Fit NET STUDY、FAXゼミ、英検対策WEB講座、くれしぇんどオンラインのポータルサイトログインページです。"
        />
      </Helmet>
      <Content />
    </>
  )
}

export default PortalGuidePage
