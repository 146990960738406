import React from 'react'
import styled from 'styled-components'
import iconOne from 'assets/images/portal_guide/icon-one.png'
import iconTwo from 'assets/images/portal_guide/icon-two.png'
import iconThree from 'assets/images/portal_guide/icon-three.png'
import mv from 'assets/images/portal_guide/mv.png'
import iconStarYellow from 'assets/images/portal_guide/icon-star-yellow.svg'
import headingLeft from 'assets/images/portal_guide/heading-left.png'
import headingRight from 'assets/images/portal_guide/heading-right.png'
import sectionTop from 'assets/images/portal_guide/section-top.png'
import sectionBottom from 'assets/images/portal_guide/section-bottom.png'
import iconStar from 'assets/images/portal_guide/icon-star.png'
import buttonContact from 'assets/images/portal_guide/button-contact.png'
import serviceCrescendo from 'assets/images/portal_guide/service-crescendo.png'
import serviceEl from 'assets/images/portal_guide/service-el.png'
import serviceFax from 'assets/images/portal_guide/service-fax.png'
import serviceFit from 'assets/images/portal_guide/service-fit.png'
import iconChrome from 'assets/images/portal_guide/icon-chrome.png'
import step1 from 'assets/images/portal_guide/step1.png'
import step2 from 'assets/images/portal_guide/step2.png'
import step3 from 'assets/images/portal_guide/step3.png'
import step4 from 'assets/images/portal_guide/step4.png'
import bookmarkPc1 from 'assets/images/portal_guide/bookmark-pc1.png'
import bookmarkPc2 from 'assets/images/portal_guide/bookmark-pc2.png'
import iconStarBlack from 'assets/images/portal_guide/icon-star-black.svg'
import bookmarkIos1 from 'assets/images/portal_guide/bookmark-ios1.png'
import bookmarkIos2 from 'assets/images/portal_guide/bookmark-ios2.png'
import bookmarkAndroid1 from 'assets/images/portal_guide/bookmark-android1.png'
import bookmarkAndroid2 from 'assets/images/portal_guide/bookmark-android2.png'
import iconMenu from 'assets/images/portal_guide/icon-menu.svg'
import iconStarPlus from 'assets/images/portal_guide/icon-star-plus.svg'
import buttonManual from 'assets/images/portal_guide/button-manual.png'
import point1 from 'assets/images/portal_guide/point1.jpg'
import point2 from 'assets/images/portal_guide/point2.jpg'
import manual from 'assets/images/portal_guide/Fit_NET_STUDY_Portal_Manual_20220314a.pdf'

export interface ContentProps {
  readonly noProps?: string
}

type ContentPropsClassName = ContentProps & {
  readonly className: string
}

function Component({ className }: ContentPropsClassName) {
  return (
    <div className={className}>
      <div className="p-first-view">
        <div className="p-first-view__content p-notice">
          <h1 className="p-notice__title">
            <span>新ポータルサイト</span> <span>ログイン方法</span>
          </h1>
        </div>
      </div>

      <section className="p-service">
        <div className="l-inner p-service__inner">
          <h2 className="c-heading-login p-service__heading">
            ログインはこちら
          </h2>
          <div className="p-service__lead">
            受講しているサービスを
            <br className="u-hidden-tab u-hidden-pc" />
            選んでください。
          </div>
          <div className="p-service__items">
            <a
              className="p-service__item p-service-item is-fit"
              href="https://fit.ktc-chuoh.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={serviceFit}
                width="250"
                height="190"
                loading="lazy"
                alt="Fit NET STUDY"
              />
            </a>
            <a
              className="p-service__item p-service-item is-fax"
              href="https://fax.ktc-chuoh.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={serviceFax}
                width="250"
                height="190"
                loading="lazy"
                alt="FAXゼミ"
              />
            </a>
            <a
              className="p-service__item p-service-item is-el"
              href="https://eiken.ktc-chuoh.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={serviceEl}
                width="250"
                height="190"
                loading="lazy"
                alt="英検対策WEB講座"
              />
            </a>
            <a
              className="p-service__item p-service-item is-crescendo"
              href="https://cres.ktc-chuoh.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={serviceCrescendo}
                width="250"
                height="190"
                loading="lazy"
                alt="くれしぇんどオンライン"
              />
            </a>
          </div>
          <div className="p-service__attention">
            ※「Fit NET STUDY」と「FAXゼミ（English
            Lab）」を併用している方は「Fit NET STUDY」でログインしてください。
          </div>
        </div>
      </section>

      <div className="p-menu">
        <div className="l-inner p-menu__inner">
          <div className="p-menu__items">
            <div className="p-menu-box p-menu__item">
              <div className="p-menu-box__icon">
                <img src={iconOne} alt="01" width="95" height="95" />
              </div>
              <div className="p-menu-box__text">
                <span>ログイン方法</span>
              </div>
            </div>
            <div className="p-menu-box p-menu__item">
              <div className="p-menu-box__icon">
                <img src={iconTwo} alt="02" width="95" height="95" />
              </div>
              <div className="p-menu-box__text">
                <span>ブックマークの方法</span>
              </div>
            </div>
            <div className="p-menu-box p-menu__item">
              <div className="p-menu-box__icon">
                <img src={iconThree} alt="03" width="95" height="95" />
              </div>
              <div className="p-menu-box__text">
                <span>
                  新ポータルサイトは
                  <br />
                  ここが使いやすい！
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="point1" className="p-point">
        <div className="l-inner">
          <div className="p-point__heading">
            <h2 className="c-heading-point is-one">
              新ポータルサイトへの
              <br className="u-hidden-tab u-hidden-pc" />
              ログイン方法
            </h2>
          </div>
          <div className="p-point__lead">
            このページがGoogle Chromeで
            <br className="u-hidden-tab u-hidden-pc" />
            開いているか確認してください。
            <br />
            ※新ポータルサイトは
            <br className="u-hidden-tab u-hidden-pc" />
            Internet Explorerは非対応です。
          </div>
          <div className="p-point__chrome">
            <div className="p-chrome-box">
              <div className="p-chrome-box__image">
                <img
                  src={iconChrome}
                  alt=""
                  width="125"
                  height="125"
                  loading="lazy"
                />
              </div>
              <div className="p-chrome-box__text">
                Google Chromeが受講端末に入っていない場合は、
                <br className="u-hidden-sp" />
                「クローム
                ダウンロード」で検索し、ページ内容に従ってダウンロードをお願いします。
              </div>
            </div>
          </div>
          <div className="p-step p-point__step">
            <div className="p-step__item p-step-item is-one">
              <div className="p-step-item__image">
                <img
                  src={step1}
                  alt=""
                  width="576"
                  height="345"
                  loading="lazy"
                />
              </div>
              <div className="p-step-item__body">
                <h3 className="p-step-item__title">
                  <span>受講しているサービスのボタンを選択</span>
                </h3>
                <div className="p-step-item__content">
                  <div className="p-step-item__text">
                    必ず受講端末でボタンを選んでください。
                  </div>
                </div>
              </div>
            </div>
            <div className="p-step__item p-step-item is-two">
              <div className="p-step-item__image">
                <img
                  src={step2}
                  alt=""
                  width="576"
                  height="345"
                  loading="lazy"
                />
              </div>
              <div className="p-step-item__body">
                <h3 className="p-step-item__title">
                  <span>IDとパスワードを入力</span>
                </h3>
                <div className="p-step-item__content">
                  <div className="p-step-item__text">
                    IDとパスワードは今後ログインする際も使用するため、メモしておいてください。
                  </div>
                </div>
              </div>
            </div>
            <div className="p-step__item p-step-item is-three">
              <div className="p-step-item__image">
                <img
                  src={step3}
                  alt=""
                  width="576"
                  height="345"
                  loading="lazy"
                />
              </div>
              <div className="p-step-item__body">
                <div className="p-step-item__title">
                  <span>ページ右上に名前が表示されたらOK</span>
                </div>
                <div className="p-step-item__content">
                  <div className="p-step-item__text">
                    自分の名前が表示されない場合は、担当の先生もしくはページ下部のお問い合わせフォームに連絡してください。
                  </div>
                </div>
              </div>
            </div>
            <div className="p-step__item p-step-item is-four">
              <div className="p-step-item__image">
                <img
                  src={step4}
                  alt=""
                  width="576"
                  height="345"
                  loading="lazy"
                />
              </div>
              <div className="p-step-item__body">
                <h3 className="p-step-item__title">
                  <span>新ポータルサイトをブックマーク</span>
                </h3>
                <div className="p-step-item__content">
                  <div className="p-step-item__text">
                    新ポータルサイトは検索では出てこないため、ブックマーク推奨です。
                  </div>
                  <div className="p-step-item__text is-has-icon">
                    ブックマークの方法は
                    <a href="#point2" className="p-step-item__section-link">
                      ブックマークの方法
                    </a>
                    を確認してください。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="point2" className="p-reserve">
        <div className="l-inner">
          <div className="p-reserve__head">
            <h2 className="c-heading-point is-two p-point__heading">
              ブックマークの方法
            </h2>
          </div>
          <div className="p-reserve__bookmark bookmarks">
            <div className="bookmarks__devices">
              <div className="bookmarks__device">
                <h3 className="bookmarks__subhead">■ PCの場合</h3>
                <div className="bookmarks__items">
                  <div className="bookmarks-item bookmarks__item">
                    <div className="bookmarks-item__image">
                      <img src={bookmarkPc1} alt="" loading="lazy" />
                    </div>
                    <div className="bookmarks-item__body">
                      <h4 className="bookmarks-item__head">
                        ブックマークを追加する
                      </h4>
                      <ol className="bookmarks-item__ol">
                        <li>
                          <span>&#9312;</span>
                          <div>
                            右上の
                            <img src={iconStarBlack} alt="" loading="lazy" />
                            マークをクリック
                          </div>
                        </li>
                        <li>
                          <span>&#9313;</span>
                          <div>「ブックマークの追加」をクリック</div>
                        </li>
                        <li>
                          <span>&#9314;</span>
                          <div>任意の名前をつけて「完了」をクリック</div>
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="bookmarks-item bookmarks__item">
                    <div className="bookmarks-item__image">
                      <img src={bookmarkPc2} alt="" loading="lazy" />
                    </div>
                    <div className="bookmarks-item__body">
                      <h4 className="bookmarks-item__head">
                        ブックマークを探す
                      </h4>
                      <ol className="bookmarks-item__ol">
                        <li>
                          <span>&#9312;</span>
                          <div>
                            右上の
                            <img src={iconMenu} alt="" loading="lazy" />
                            をクリック
                          </div>
                        </li>
                        <li>
                          <span>&#9313;</span>
                          <div>「ブックマーク」をクリック</div>
                        </li>
                        <li>
                          <span>※</span>
                          <div>
                            ブックマークバーを使うと、アドレスバーの下にブックマークが表示されます。
                          </div>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bookmarks__device">
                <h3 className="bookmarks__subhead">■ iPadの場合</h3>
                <div className="bookmarks__items">
                  <div className="bookmarks-item bookmarks__item">
                    <div className="bookmarks-item__image">
                      <img src={bookmarkIos1} alt="" loading="lazy" />
                    </div>
                    <div className="bookmarks-item__body">
                      <h4 className="bookmarks-item__head">
                        ブックマークを追加する
                      </h4>
                      <ol className="bookmarks-item__ol">
                        <li>
                          <span>&#9312;</span>
                          <div>
                            右上の
                            <img src={iconMenu} alt="" loading="lazy" />
                            をタップ
                          </div>
                        </li>
                        <li>
                          <span>&#9313;</span>
                          <div>
                            <img
                              className="is-first"
                              src={iconStarPlus}
                              alt=""
                              loading="lazy"
                            />
                            ［ブックマーク］をタップ
                          </div>
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="bookmarks-item bookmarks__item">
                    <div className="bookmarks-item__image">
                      <img src={bookmarkIos2} alt="" loading="lazy" />
                    </div>
                    <div className="bookmarks-item__body">
                      <h4 className="bookmarks-item__head">
                        ブックマークを探す
                      </h4>
                      <ol className="bookmarks-item__ol">
                        <li>
                          <span>&#9312;</span>
                          <div>
                            右上の
                            <img src={iconMenu} alt="" loading="lazy" />
                            をタップ
                          </div>
                        </li>
                        <li>
                          <span>&#9313;</span>
                          <div>
                            <img
                              className="is-first"
                              src={iconStarBlack}
                              alt=""
                              loading="lazy"
                            />
                            ［ブックマーク］をタップ
                          </div>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bookmarks__device">
                <h3 className="bookmarks__subhead">■ Android端末の場合</h3>
                <div className="bookmarks__items">
                  <div className="bookmarks-item bookmarks__item">
                    <div className="bookmarks-item__image">
                      <img src={bookmarkAndroid1} alt="" loading="lazy" />
                    </div>
                    <div className="bookmarks-item__body">
                      <h4 className="bookmarks-item__head">
                        ブックマークを追加する
                      </h4>
                      <ol className="bookmarks-item__ol">
                        <li>
                          <span>&#9312;</span>
                          <div>
                            上の
                            <img src={iconStarBlack} alt="" loading="lazy" />
                            マークをタップ
                          </div>
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="bookmarks-item bookmarks__item">
                    <div className="bookmarks-item__image">
                      <img src={bookmarkAndroid2} alt="" loading="lazy" />
                    </div>
                    <div className="bookmarks-item__body">
                      <h4 className="bookmarks-item__head">
                        ブックマークを探す
                      </h4>
                      <ol className="bookmarks-item__ol">
                        <li>
                          <span>&#9312;</span>
                          <div>
                            右上の
                            <img src={iconMenu} alt="" loading="lazy" />
                            をタップ
                          </div>
                        </li>
                        <li>
                          <span>&#9313;</span>
                          <div>
                            <img
                              className="is-first"
                              src={iconStarBlack}
                              alt=""
                              loading="lazy"
                            />
                            ブックマークをタップ
                          </div>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="p-manual">
        <div className="p-manual-text">
          その他使い方の詳細は
          <br className="u-hidden-pc u-hidden-tab" />
          以下のボタンからご確認ください。
        </div>
        <div className="p-manual-link">
          <a href={manual} target="_blank" rel="noreferrer noopener">
            <img
              src={buttonManual}
              alt="利用マニュアルはこちら"
              width="616"
              height="123"
              loading="lazy"
            />
          </a>
        </div>
      </div>

      <section id="point3" className="p-about">
        <div className="l-inner">
          <div className="p-about__head">
            <h2 className="c-heading-point is-three p-point__heading">
              新ポータルサイトは
              <br className="u-hidden-tab u-hidden-pc" />
              ここが使いやすい！
            </h2>
          </div>
          <div className="p-about__items">
            <div className="p-about__item">
              <div className="p-recommend-card">
                <div className="p-recommend-card__body">
                  <h3 className="p-recommend-card__title">
                    <span>受講がよりスムーズに</span>
                  </h3>
                  <div className="p-recommend-card__text">
                    新ポータルサイトでは、1クリックで授業の部屋に入れるから、落ち着いて授業に取り組むことができます。
                  </div>
                </div>
                <div className="p-recommend-card__image">
                  <img src={point1} alt="" width="454" height="262" />
                </div>
              </div>
            </div>
            <div className="p-about__item">
              <div className="p-recommend-card">
                <div className="p-recommend-card__body">
                  <h3 className="p-recommend-card__title">
                    <span>見やすいスケジュール</span>
                  </h3>
                  <div className="p-recommend-card__text">
                    新ポータルサイトは見た目も大きくリニューアルしました！今までより見やすいスケジュールで学習の習慣付けをサポートします。
                  </div>
                </div>
                <div className="p-recommend-card__image">
                  <img src={point2} alt="" width="454" height="262" />
                </div>
              </div>
            </div>
          </div>
          <div className="p-about__future">今後も随時アップデート予定！！</div>
        </div>
      </section>

      <section className="p-service is-strong">
        <div className="l-inner p-service__inner">
          <h2 className="c-heading-login p-service__heading">
            ログインはこちら
          </h2>
          <div className="p-service__lead">
            受講しているサービスを
            <br className="u-hidden-tab u-hidden-pc" />
            選んでください。
          </div>
          <div className="p-service__items">
            <a
              className="p-service__item p-service-item is-fit"
              href="https://fit.ktc-chuoh.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={serviceFit}
                width="250"
                height="190"
                loading="lazy"
                alt="Fit NET STUDY"
              />
            </a>
            <a
              className="p-service__item p-service-item is-fax"
              href="https://fax.ktc-chuoh.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={serviceFax}
                width="250"
                height="190"
                loading="lazy"
                alt="FAXゼミ"
              />
            </a>
            <a
              className="p-service__item p-service-item is-el"
              href="https://eiken.ktc-chuoh.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={serviceEl}
                width="250"
                height="190"
                loading="lazy"
                alt="英検対策WEB講座"
              />
            </a>
            <a
              className="p-service__item p-service-item is-crescendo"
              href="https://cres.ktc-chuoh.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={serviceCrescendo}
                width="250"
                height="190"
                loading="lazy"
                alt="くれしぇんどオンライン"
              />
            </a>
          </div>
          <div className="p-service__attention">
            ※「Fit NET STUDY」と「FAXゼミ（English
            Lab）」を併用している方は「Fit NET STUDY」でログインしてください。
          </div>
        </div>
      </section>

      <div className="p-contact">
        <div className="l-inner p-contact__inner">
          <div className="p-contact__text">
            ご質問・ご相談などは以下のボタンより
            <br className="u-hidden-pc u-hidden-tab" />
            お問い合わせください。
          </div>
          <div className="p-contact__button">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSek6isnR8rtYn7eHdn3izrdzNMlicBaavIdLm2m0yFIPbb3Sg/viewform"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={buttonContact}
                alt="お問い合わせはコチラ"
                width="616"
                height="123"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<ContentPropsClassName>`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'palt';
  color: #0a0a0a;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  min-height: -webkit-fill-available;
  overflow-wrap: break-word;
  overflow-x: hidden;

  article,
  aside,
  footer,
  header,
  nav,
  section,
  main {
    display: block;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  span,
  em,
  small,
  strong,
  sub,
  sup,
  mark,
  del,
  ins,
  strike,
  abbr,
  dfn,
  blockquote,
  q,
  cite,
  code,
  pre,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  div,
  section,
  article,
  main,
  aside,
  nav,
  header,
  hgroup,
  footer,
  img,
  figure,
  figcaption,
  address,
  time,
  audio,
  video,
  canvas,
  iframe,
  details,
  summary,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    border: 0;
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    outline: none;
  }

  img {
    border: none;
    height: auto;
    max-width: 100%;
    vertical-align: top;
  }

  video {
    height: auto;
    max-width: 100%;
  }

  ul,
  ol {
    list-style: none;
  }

  table {
    border: 1px solid #eee;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    width: 100%;
  }

  th,
  td {
    border: 1px solid #eee;
    padding: 4px 6px;
    text-align: left;
    vertical-align: top;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::after,
  blockquote::before,
  q::after,
  q::before {
    content: '';
    content: none;
  }

  address {
    font-style: normal;
  }

  .l-inner {
    height: inherit;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 24px;
    width: 1198px;
  }

  .c-heading-point {
    align-items: center;
    display: inline-flex;
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: left;
  }

  .c-heading-point::before {
    background: transparent url(${iconOne}) no-repeat center center/contain;
    content: '';
    flex-shrink: 0;
    height: 95px;
    height: 5.9375rem;
    margin-right: 26px;
    margin-right: 1.625rem;
    width: 95px;
    width: 5.9375rem;
  }

  .c-heading-point.is-one::before {
    background-image: url(${iconOne});
  }

  .c-heading-point.is-two::before {
    background-image: url(${iconTwo});
  }

  .c-heading-point.is-three::before {
    background-image: url(${iconThree});
  }

  .c-heading-login {
    align-items: flex-end;
    display: flex;
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1;
  }

  .c-heading-login::before,
  .c-heading-login::after {
    background: transparent url(${headingLeft}) no-repeat center center/contain;
    content: '';
    height: 59.95px;
    height: 3.74688rem;
    width: 88.73px;
    width: 5.54563rem;
  }

  .c-heading-login::before {
    margin-right: 18px;
    margin-right: 1.125rem;
  }

  .c-heading-login::after {
    background-image: url(${headingRight});
    margin-left: 18px;
    margin-left: 1.125rem;
  }

  .p-about {
    padding-bottom: 80px;
    padding-bottom: 5rem;
    padding-top: 80px;
    padding-top: 5rem;
  }

  .p-about__head {
    text-align: center;
  }

  .p-about__items {
    margin-top: 60px;
    margin-top: 3.75rem;
    margin-top: 60px;
    margin-top: 3.75rem;
  }

  .p-about__item {
    text-align: center;
  }

  .p-about__item:nth-child(n + 2) {
    margin-top: 80px;
    margin-top: 5rem;
  }

  .p-about__future {
    font-size: 30px;
    font-size: 1.875rem;
    margin-top: 36px;
    margin-top: 2.25rem;
    text-align: center;
  }

  .p-chrome-box {
    align-items: center;
    display: inline-flex;
    max-width: 100%;
    width: 862px;
    width: 53.875rem;
  }

  .p-chrome-box__image {
    flex-shrink: 0;
    padding-left: 10px;
    padding-left: 0.625rem;
    width: 125px;
    width: 7.8125rem;
  }

  .p-chrome-box__image img {
    display: block;
  }

  .p-chrome-box__text {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.46667;
    margin-left: 20px;
    margin-left: 1.25rem;
    padding-left: 1em;
    position: relative;
    text-align: left;
    width: calc(100% - 125px - 20px);
    width: calc(100% - 7.8125rem - 1.25rem);
  }

  .p-chrome-box__text::before {
    content: '※';
    left: 0;
    position: absolute;
    top: 0;
  }

  .p-contact {
    padding-bottom: 80px;
    padding-bottom: 5rem;
    padding-top: 80px;
    padding-top: 5rem;
  }

  .p-contact__text {
    font-size: 26px;
    font-size: 1.625rem;
    text-align: center;
  }

  .p-contact__button {
    margin-top: 36px;
    margin-top: 2.25rem;
    text-align: center;
  }

  .p-contact__button a {
    transition: all 0.3s ease 0s;
  }

  .p-contact__button img {
    max-width: 100%;
    transform: scale(1);
    transition: all 0.3s ease 0s;
    width: 616px;
    width: 38.5rem;
  }

  .p-contact__button:hover img {
    transform: scale(1.05);
  }

  .p-first-view {
    background: #d9f7ff url(${mv}) repeat-x center center/auto 100%;
    padding-bottom: 86px;
    padding-bottom: 5.375rem;
    padding-top: 86px;
    padding-top: 5.375rem;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .p-first-view__content {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 1000px;
    width: 62.5rem;
  }

  .p-menu-box {
    align-items: center;
    background: #ffffff;
    border: 2px solid #070203;
    border: 0.125rem solid #070203;
    display: inline-flex;
    justify-content: center;
    padding: 50px 12px 42px;
    padding: 3.125rem 0.75rem 2.625rem;
    position: relative;
    text-decoration: none;
  }

  .p-menu-box__icon {
    height: 95px;
    height: 5.9375rem;
    left: 50%;
    position: absolute;
    top: -47.5px;
    top: -2.96875rem;
    transform: translateX(-50%);
    width: 95px;
    width: 5.9375rem;
  }

  .p-menu-box__icon img {
    display: block;
  }

  .p-menu-box__text {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 700;
  }

  .p-menu-box__text span {
    background: linear-gradient(transparent 80%, #fdf003 0);
    display: inline;
  }

  .p-menu {
    background: #d9f7ff;
    padding-bottom: 100px;
    padding-bottom: 6.25rem;
    padding-top: 120px;
    padding-top: 7.5rem;
  }

  .p-menu__inner {
    width: 1048px;
    width: 62.5rem;
  }

  .p-menu__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .p-menu__item {
    width: calc(33.3% - 40px);
    width: calc(33.3% - 2.5rem);
  }

  .p-menu__item:not(:nth-child(3n + 1)) {
    margin-left: 60px;
    margin-left: 3.75rem;
  }

  .p-notice {
    background: #fff;
    padding: 50px 24px;
    padding: 3.125rem 1.5rem;
    text-align: center;
  }

  .p-notice__title {
    color: #00c8ff;
    font-size: 56px;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.5;
  }

  .p-notice__title span {
    display: inline-block;
  }

  .p-notice__message,
  .p-notice__message2 {
    color: #00c8ff;
    font-size: 32px;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.6;
  }

  .p-notice__message span,
  .p-notice__message2 span {
    display: inline-block;
  }

  .p-notice__message {
    margin-top: 24px;
    margin-top: 1.5rem;
  }

  .p-notice__description {
    color: #7d7d7d;
    font-size: 20px;
    font-size: 1.25rem;
    margin-top: 24px;
    margin-top: 1.5rem;
    padding-left: calc(1em + 2px);
    padding-left: calc(1em + 0.125rem);
    position: relative;
    text-align: left;
  }

  .p-notice__description::before {
    content: '※';
    left: 0;
    position: absolute;
    top: 0;
  }

  .p-point {
    padding-bottom: 80px;
    padding-bottom: 5rem;
    padding-top: 80px;
    padding-top: 5rem;
  }

  .p-manual {
    padding-bottom: 3.125rem;
    padding-top: 3.125rem;
    text-align: center;
  }

  .p-manual-text {
    font-size: 1.0625rem;
  }

  .p-manual-link {
    margin-top: 0.625rem;
  }

  .p-manual-link a {
    text-decoration: none;
  }

  .p-manual-link a img {
    max-width: 100%;
    transform: scale(1);
    transition: all 0.3s ease 0s;
    width: 20rem;
  }

  .p-manual-link a:hover img {
    transform: scale(1.05);
  }

  .p-recommend-card {
    display: inline-flex;
    flex-wrap: wrap;
    max-width: 100%;
    width: 957px;
    width: 59.8125rem;
  }

  .p-recommend-card__body {
    padding-right: 42px;
    padding-right: 2.625rem;
    padding-top: 20px;
    padding-top: 1.25rem;
    text-align: left;
    width: 50%;
  }

  .p-recommend-card__title {
    align-items: center;
    display: flex;
    font-size: 30px;
    font-size: 1.875rem;
    font-weight: 700;
  }

  .p-recommend-card__title::before {
    background: transparent url(${iconStar}) no-repeat center center/contain;
    content: '';
    flex-shrink: 0;
    height: 58px;
    height: 3.625rem;
    margin-right: 18px;
    margin-right: 1.125rem;
    width: 2.8125rem;
  }

  .p-recommend-card__title span {
    background: linear-gradient(transparent 80%, #fdf003 0);
    display: inline;
  }

  .p-recommend-card__text {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.42308;
    margin-top: 12px;
    margin-top: 0.75rem;
    padding-left: 2.8125rem;
    padding-left: 2.8125rem;
  }

  .p-recommend-card__image {
    width: 50%;
  }

  .p-recommend-card__image img {
    border: 1px solid #707070;
    display: block;
    width: 100%;
  }

  .p-reserve-box {
    background: #fff;
    border: 2px solid #0a0a0a;
    border: 0.125rem solid #0a0a0a;
    display: inline-block;
    max-width: 100%;
    padding: 26px 98px;
    padding: 1.625rem 6.125rem;
    width: 996px;
    width: 62.25rem;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .p-reserve-box__content {
    text-align: left;
  }

  .p-reserve-box__content li {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.6;
  }

  .p-reserve-box__content li:nth-child(n + 2) {
    margin-top: 5px;
    margin-top: 0.3125rem;
  }

  .p-reserve-box__content li span {
    display: inline-block;
  }

  .p-reserve {
    background: #d9f7ff;
    padding-bottom: 80px;
    padding-bottom: 5rem;
    padding-top: 80px;
    padding-top: 5rem;
  }

  .p-reserve__head {
    text-align: center;
  }

  .p-reserve__lead {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.6;
    margin-top: 42px;
    margin-top: 2.625rem;
  }

  .p-reserve__box {
    margin-top: 36px;
    margin-top: 2.25rem;
    text-align: center;
  }

  .p-service-item {
    display: inline-block;
    overflow: hidden;
    position: relative;
    transform: scale(1) translateY(0);
    transition: all 0.3s ease 0s;
  }

  .p-service-item img {
    display: block;
    image-rendering: -webkit-optimize-contrast;
    width: 100%;
  }

  .p-service-item:hover {
    transform: scale(1.1);
  }

  .p-service {
    padding-bottom: 100px;
    padding-bottom: 6.25rem;
    padding-top: 100px;
    padding-top: 6.25rem;
    position: relative;
  }

  .p-service.is-strong {
    background: #fdf335;
  }

  .p-service::before,
  .p-service::after {
    background: transparent url(${sectionTop}) repeat-x bottom center/auto 100%;
    content: '';
    height: 10px;
    height: 0.625rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2;
  }

  .p-service::before {
    top: -1px;
  }

  .p-service::after {
    background-image: url(${sectionBottom});
    bottom: -1px;
  }

  .p-service__heading {
    text-align: center;
  }

  .p-service__lead {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 500;
    margin-top: 20px;
    margin-top: 1.25rem;
    text-align: center;
  }

  .p-service__items {
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 46px;
    margin-top: 2.875rem;
  }

  .p-service__item {
    width: calc(25% - 37.5px - 1px);
    width: calc(25% - 2.34375rem - 1px);
  }

  .p-service__item:not(:nth-child(4n + 1)) {
    margin-left: 50px;
    margin-left: 3.125rem;
  }

  .p-service__attention {
    font-size: 18px;
    font-size: 1.125rem;
    margin-top: 50px;
    margin-top: 3.125rem;
    text-align: center;
  }

  .p-step-item {
    display: flex;
    flex-wrap: wrap;
  }

  .p-step-item__image {
    width: 50%;
  }

  .p-step-item__image img {
    border: 1px solid #707070;
    image-rendering: -webkit-optimize-contrast;
    width: 100%;
  }

  .p-step-item__body {
    padding-left: 26px;
    padding-left: 1.625rem;
    padding-top: 18px;
    padding-top: 1.125rem;
    width: 50%;
  }

  .p-step-item__title {
    font-size: 30px;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 1.46667;
    padding-left: 3.375rem;
    position: relative;
  }

  .p-step-item__title span {
    background: linear-gradient(transparent 80%, #fdf003 0);
    display: inline;
  }

  .p-step-item__title::before {
    content: '①';
    font-size: 40px;
    font-size: 2.5rem;
    left: 0;
    line-height: 44px;
    line-height: 2.75rem;
    position: absolute;
    top: 0;
  }

  .is-one .p-step-item__title::before {
    content: '①';
  }

  .is-two .p-step-item__title::before {
    content: '②';
  }

  .is-three .p-step-item__title::before {
    content: '③';
  }

  .is-four .p-step-item__title::before {
    content: '④';
  }

  .p-step-item__content {
    margin-top: 10px;
    margin-top: 0.625rem;
  }

  .p-step-item__text {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.42308;
    padding-left: calc(3.375rem + 1em + 2px);
    position: relative;
  }

  .p-step-item__text a {
    text-decoration: none;
  }

  .p-step-item__text:nth-child(n + 2) {
    margin-top: 18px;
    margin-top: 1.125rem;
  }

  .p-step-item__text::before {
    content: '※';
    left: 3.375rem;
    position: absolute;
    top: 0;
  }

  .p-step-item__section-link {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 0em;
    padding-left: 2.1875rem;
    position: relative;
  }

  .p-step-item__section-link::before {
    background: transparent url(${iconTwo}) no-repeat center center/contain;
    content: '';
    height: 1.875rem;
    left: 0;
    margin-right: 3px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: top;
    width: 1.875rem;
  }

  .p-step__item:nth-child(n + 2) {
    margin-top: 80px;
    margin-top: 5rem;
  }

  .p-point__heading {
    text-align: center;
  }

  .p-point__time {
    color: #f00;
    font-size: 35px;
    font-size: 2.1875rem;
    margin-top: 32px;
    margin-top: 2rem;
    text-align: center;
  }

  .p-point__time span {
    display: inline-block;
  }

  .p-point__lead {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.6;
    margin-top: 12px;
    margin-top: 0.75rem;
    text-align: center;
  }

  .p-point__chrome {
    margin-top: 60px;
    margin-top: 3.75rem;
    text-align: center;
  }

  .p-point__step {
    margin-top: 70px;
    margin-top: 4.375rem;
  }

  .p-point__attention {
    color: #ff0000;
    font-size: 35px;
    font-size: 2.1875rem;
    margin-top: 66px;
    margin-top: 4.125rem;
    text-align: center;
  }

  .p-point__attention::before {
    content: '※';
  }

  .p-point__attention span {
    display: inline-block;
  }

  .p-reserve__bookmark {
    margin-top: 1.25rem;
  }

  .bookmarks__device {
    max-width: 100%;
  }

  .bookmarks__device:nth-child(n + 2) {
    margin-top: 3.125rem;
  }

  .bookmarks__subhead {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
  }

  .bookmarks__item:nth-child(n + 2) {
    margin-top: 1.25rem;
  }

  .bookmarks-item {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .bookmarks-item__image {
    margin-top: 0.625rem;
    order: 2;
    padding-left: 2.3125rem;
  }

  .bookmarks-item__body {
    display: contents;
    padding: 1.25rem 0 0 0;
  }

  .bookmarks-item__head {
    align-items: flex-end;
    display: flex;
    font-size: 1.125rem;
    font-weight: 500;
    order: 1;
  }

  .bookmarks-item__head::before {
    background: transparent url(${iconStarYellow}) no-repeat center
      center/contain;
    content: '';
    flex-shrink: 0;
    height: 2.175rem;
    margin-right: 0.625rem;
    width: 1.6875rem;
  }

  .bookmarks-item__ol {
    font-size: 1rem;
    margin-top: 0.625rem;
    order: 3;
    padding-left: 2.3125rem;
  }

  .bookmarks-item__ol li {
    display: flex;
    font-weight: 500;
    line-height: 1.5;
  }

  .bookmarks-item__ol li:nth-child(n + 2) {
    margin-top: 0.25rem;
  }

  .bookmarks-item__ol li span {
    content: '';
    flex-shrink: 0;
    margin-right: 0.375rem;
  }

  .bookmarks-item__ol li img {
    height: 1em;
    margin: 0 0.25rem -0.125rem 0.25rem;
    vertical-align: baseline;
    width: auto;
  }

  .bookmarks-item__ol li img.is-first {
    margin-left: 0;
  }

  @media (min-width: 768px) {
    .p-manual {
      padding-bottom: 4.347826087vw;
      padding-top: 4.347826087vw;
    }

    .p-manual-text {
      font-size: 2.2608695652vw;
    }

    .p-manual-link {
      margin-top: 1.7391304348vw;
    }

    .p-manual-link a img {
      width: 53.5652173913vw;
    }

    .p-step-item__section-link {
      padding-left: 4.7826086957vw;
    }

    .p-step-item__section-link::before {
      height: 4.347826087vw;
      width: 4.347826087vw;
    }

    .bookmarks__device {
      margin-left: auto;
      margin-right: auto;
      text-align: left;
      width: 61.25rem;
    }

    .bookmarks__device:nth-child(n + 2) {
      margin-top: 8.6956521739vw;
    }

    .bookmarks__subhead {
      font-size: 2.6086956522vw;
      margin-bottom: 1.7391304348vw;
    }

    .bookmarks__item:nth-child(n + 2) {
      margin-top: 3.4782608696vw;
    }

    .bookmarks-item {
      flex-direction: row;
    }

    .bookmarks-item__image {
      margin-top: 0;
      order: 0;
      padding-left: 0;
      width: 50%;
    }

    .bookmarks-item__body {
      display: block;
      padding-left: 1.7391304348vw;
      padding-top: 0;
      width: 50%;
    }

    .bookmarks-item__head {
      align-items: flex-end;
      display: flex;
      font-size: 2.6086956522vw;
      font-weight: 500;
      order: 0;
    }

    .bookmarks-item__head::before {
      height: 5.0434782609vw;
      margin-right: 0.625rem;
      width: 3.9130434783vw;
    }

    .bookmarks-item__ol {
      font-size: 2.2608695652vw;
      margin-top: 1.7391304348vw;
      order: 0;
      padding-left: 0.8695652174vw;
    }

    .bookmarks-item__ol li {
      display: flex;
      font-weight: 400;
      line-height: 1.5;
    }

    .bookmarks-item__ol li:nth-child(n + 2) {
      margin-top: 1.3913043478vw;
    }

    .bookmarks-item__ol li span {
      margin-right: 0.6956521739vw;
    }
  }

  @media (min-width: 768px) and (max-width: 1149px) {
    .u-hidden-tab {
      display: none;
    }
  }

  @media (max-width: 1149px) and (min-width: 768px) {
    .p-step-item__text.is-has-icon {
      line-height: 1.6;
    }
  }

  @media (min-width: 1150px) {
    .p-manual {
      padding-bottom: 3.125rem;
      padding-top: 3.125rem;
    }

    .p-manual-text {
      font-size: 1.625rem;
    }

    .p-manual-link {
      margin-top: 1.25rem;
    }

    .p-manual-link a img {
      width: 38.5rem;
    }

    .p-step-item__section-link {
      padding-left: 3.4375rem;
    }

    .p-step-item__section-link::before {
      height: 3.125rem;
      width: 3.125rem;
    }

    .bookmarks__device:nth-child(n + 2) {
      margin-top: 6.25rem;
    }

    .bookmarks__subhead {
      font-size: 1.875rem;
      margin-bottom: 1.25rem;
    }

    .bookmarks__item:nth-child(n + 2) {
      margin-top: 2.5rem;
    }

    .bookmarks-item__body {
      padding-left: 1.25rem;
    }

    .bookmarks-item__head {
      font-size: 1.875rem;
    }

    .bookmarks-item__head::before {
      height: 3.625rem;
      margin-right: 0.625rem;
      width: 2.8125rem;
    }

    .bookmarks-item__ol {
      font-size: 1.625rem;
      margin-top: 1.25rem;
      padding-left: 0.625rem;
    }

    .bookmarks-item__ol li:nth-child(n + 2) {
      margin-top: 1rem;
    }

    .bookmarks-item__ol li span {
      margin-right: 0.5rem;
    }
  }

  @media not all and (max-width: 1149px) {
    html {
      font-size: 13px;
    }

    .u-hidden-pc {
      display: none;
    }
  }

  @media (max-width: 1149px) {
    .l-inner {
      padding-left: 32px;
      padding-right: 32px;
    }

    .c-heading-point {
      font-size: 3.47826vw;
      text-align: left;
    }

    .c-heading-point::before {
      height: 8.26087vw;
      margin-right: 2.26087vw;
      width: 8.26087vw;
    }

    .c-heading-login {
      font-size: 3.47826vw;
    }

    .c-heading-login::before,
    .c-heading-login::after {
      height: 5.21304vw;
      width: 7.71565vw;
    }

    .c-heading-login::before {
      margin-right: 1.56522vw;
    }

    .c-heading-login::after {
      margin-left: 1.56522vw;
    }

    .p-about {
      padding-bottom: 6.95652vw;
      padding-top: 6.95652vw;
    }

    .p-about__items {
      margin-top: 5.21739vw;
    }

    .p-about__item:nth-child(n + 2) {
      margin-top: 3.47826vw;
    }

    .p-about__future {
      font-size: 2.6087vw;
      margin-top: 3.13043vw;
    }

    .p-chrome-box__image {
      width: 10.86957vw;
    }

    .p-chrome-box__text {
      font-size: 2.6087vw;
      margin-left: 1.73913vw;
      width: calc(100% - 10.86957vw - 1.73913vw);
    }

    .p-contact {
      padding-bottom: 6.95652vw;
      padding-top: 6.95652vw;
    }

    .p-contact__text {
      font-size: 2.26087vw;
    }

    .p-contact__button {
      margin-top: 3.13043vw;
    }

    .p-contact__button img {
      width: 53.56522vw;
    }

    .p-first-view {
      padding-bottom: 7.47826vw;
      padding-top: 7.47826vw;
    }

    .p-first-view__content {
      width: calc(100% - 64px);
    }

    .p-first-view__content {
      width: calc(100% - 64px);
    }

    .p-menu-box {
      background: #ffffff;
      padding-bottom: 3.65217vw;
      padding-top: 4.34783vw;
    }

    .p-menu-box__icon {
      height: 8.26087vw;
      top: -4.13043vw;
      width: 8.26087vw;
    }

    .p-menu-box__text {
      font-size: 2.26087vw;
    }

    .p-menu {
      padding-bottom: 8.69565vw;
      padding-top: 10.43478vw;
    }

    .p-menu__item {
      width: calc(33.3% - 5.21739vw - 0.6666666667px);
    }

    .p-menu__item:not(:nth-child(3n + 1)) {
      margin-left: 5.21739vw;
    }

    .p-notice {
      padding: 4.34783vw 2.08696vw;
    }

    .p-notice__title {
      font-size: 4.86957vw;
    }

    .p-notice__message,
    .p-notice__message2 {
      font-size: 2.78261vw;
    }

    .p-notice__description {
      font-size: 18px;
    }

    .p-point {
      padding-bottom: 6.95652vw;
      padding-top: 6.95652vw;
    }

    .p-recommend-card__body {
      padding-right: 3.65217vw;
      padding-top: 1.73913vw;
    }

    .p-recommend-card__title {
      font-size: 2.6087vw;
    }

    .p-recommend-card__title::before {
      height: 5.04348vw;
      margin-right: 1.56522vw;
      width: 3.91304vw;
    }

    .p-recommend-card__text {
      font-size: 2.26087vw;
      margin-top: 1.04348vw;
      padding-left: 5.47826vw;
    }

    .p-reserve-box__content li {
      font-size: 2.6087vw;
    }

    .p-reserve {
      padding-bottom: 6.95652vw;
      padding-top: 6.95652vw;
    }

    .p-reserve__lead {
      font-size: 2.6087vw;
      margin-top: 3.65217vw;
    }

    .p-reserve__box {
      margin-top: 3.13043vw;
    }

    .p-service {
      padding-bottom: 8.69565vw;
      padding-top: 8.69565vw;
    }

    .p-service__lead {
      font-size: 2.26087vw;
      margin-top: 1.73913vw;
    }

    .p-service__items {
      margin-top: 4vw;
    }

    .p-service__item {
      width: calc(25% - 3.2608725vw - 1px);
    }

    .p-service__item:not(:nth-child(4n + 1)) {
      margin-left: 4.34783vw;
    }

    .p-service__attention {
      font-size: 17px;
      margin-top: 4.34783vw;
    }

    .p-step-item__body {
      padding-left: 2.26087vw;
      padding-top: 1.56522vw;
    }

    .p-step-item__title {
      font-size: 2.6087vw;
      padding-left: 4.69565vw;
    }

    .p-step-item__title::before {
      font-size: 3.47826vw;
      line-height: 3.82609vw;
    }

    .p-step-item__text {
      font-size: 2.26087vw;
      padding-left: calc(4.69565vw + 1em + 2px);
    }

    .p-step-item__text.is-has-icon {
      line-height: 1.8;
    }

    .p-step-item__text::before {
      left: 4.69565vw;
    }

    .p-step__item:nth-child(n + 2) {
      margin-top: 6.95652vw;
    }

    .p-point__time {
      font-size: 3.04348vw;
      margin-top: 2.78261vw;
    }

    .p-point__lead {
      font-size: 2.6087vw;
    }

    .p-point__chrome {
      margin-left: auto;
      margin-right: auto;
      margin-top: 5.21739vw;
      width: 72.17391vw;
    }

    .p-point__step {
      margin-top: 6.08696vw;
    }

    .p-point__attention {
      font-size: 3.04348vw;
      margin-top: 5.73913vw;
    }
  }

  @media (max-width: 767px) {
    .l-inner {
      padding-left: 32px;
      padding-right: 32px;
    }

    .c-heading-point {
      font-size: 22px;
      text-align: left;
    }

    .c-heading-point::before {
      height: 62px;
      margin-right: 12px;
      width: 62px;
    }

    .c-heading-login {
      font-size: 24px;
    }

    .c-heading-login::before,
    .c-heading-login::after {
      height: 29.975px;
      width: 44.365px;
    }

    .c-heading-login::before {
      margin-right: 10px;
    }

    .c-heading-login::after {
      margin-left: 10px;
    }

    .p-about {
      padding-bottom: 40px;
      padding-top: 40px;
    }

    .p-about__items {
      margin-top: 30px;
    }

    .p-about__item:nth-child(n + 2) {
      margin-top: 40px;
    }

    .p-about__future {
      font-size: 18px;
      margin-top: 20px;
    }

    .p-chrome-box__image {
      width: 50px;
    }

    .p-chrome-box__text {
      font-size: 16px;
      margin-left: 0;
      width: calc(100% - 50px);
    }

    .p-contact {
      padding-bottom: 40px;
      padding-top: 40px;
    }

    .p-contact__text {
      font-size: 17px;
    }

    .p-contact__button {
      margin-top: 18px;
    }

    .p-contact__button img {
      width: 320px;
    }

    .p-first-view {
      padding-bottom: 26px;
      padding-top: 26px;
    }

    .p-menu-box {
      border-width: 1px;
      padding-bottom: 30px;
      padding-top: 42px;
    }

    .p-menu-box__icon {
      height: 60px;
      top: -30px;
      width: 60px;
    }

    .p-menu-box__text {
      font-size: 20px;
    }

    .p-menu {
      padding-bottom: 60px;
      padding-top: 70px;
    }

    .p-menu__item {
      width: 100%;
    }

    .p-menu__item:not(:nth-child(3n + 1)) {
      margin-left: 0;
      margin-top: 60px;
    }

    .p-notice {
      padding: 20px 10px;
    }

    .p-notice__title {
      font-size: 32px;
    }

    .p-notice__message,
    .p-notice__message2 {
      font-size: 18px;
    }

    .p-notice__message2 {
      margin-top: 8px;
    }

    .p-notice__description {
      font-size: 14px;
      line-height: 1.8;
    }

    .p-point {
      padding-bottom: 40px;
      padding-top: 40px;
    }

    .p-recommend-card__body {
      padding-right: 0;
      padding-top: 0;
      width: 100%;
    }

    .p-recommend-card__title {
      font-size: 20px;
    }

    .p-recommend-card__title::before {
      height: 34.8px;
      margin-right: 10px;
      width: 27px;
    }

    .p-recommend-card__text {
      font-size: 16px;
      margin-top: 8px;
      padding-left: 37px;
    }

    .p-recommend-card__image {
      margin-top: 16px;
      padding-left: 38px;
      width: 100%;
    }

    .p-reserve-box {
      border-width: 1px;
      padding: 12px 20px;
    }

    .p-reserve-box__content li {
      font-size: 17px;
    }

    .p-reserve-box__content li:nth-child(n + 2) {
      margin-top: 10px;
    }

    .p-reserve {
      padding-bottom: 40px;
      padding-top: 40px;
    }

    .p-reserve__lead {
      font-size: 16px;
      margin-top: 20px;
    }

    .p-reserve__box {
      margin-top: 18px;
    }

    .p-service {
      padding-bottom: 60px;
      padding-top: 60px;
    }

    .p-service__lead {
      font-size: 18px;
    }

    .p-service__items {
      margin-top: 20px;
    }

    .p-service__item {
      width: calc(50% - 10px);
    }

    .p-service__item:not(:nth-child(4n + 1)) {
      margin-left: 0;
    }

    .p-service__item:not(:nth-child(2n + 1)) {
      margin-left: 20px;
    }

    .p-service__item:nth-child(n + 3) {
      margin-top: 20px;
    }

    .p-service__attention {
      font-size: 16px;
      margin-top: 24px;
    }

    .p-step-item {
      flex-direction: column;
    }

    .p-step-item__image {
      margin-top: 12px;
      order: 2;
      padding-left: 28px;
      width: 100%;
    }

    .p-step-item__body {
      display: contents;
      padding-left: 0;
      padding-top: 16px;
      width: 100%;
    }

    .p-step-item__title {
      font-size: 19px;
      font-weight: 700;
      order: 1;
      padding-left: 28px;
    }

    .p-step-item__title::before {
      font-size: 24px;
      line-height: 32px;
    }

    .p-step-item__content {
      margin-top: 16px;
      order: 3;
    }

    .p-step-item__text {
      font-size: 16px;
      padding-left: calc(28px + 1em + 2px);
    }

    .p-step-item__text:nth-child(n + 2) {
      margin-top: 10px;
    }

    .p-step-item__text::before {
      left: 28px;
    }

    .p-step__item:nth-child(n + 2) {
      margin-top: 40px;
    }

    .p-point__time {
      font-size: 18px;
      margin-top: 16px;
    }

    .p-point__lead {
      font-size: 16px;
      margin-top: 10px;
    }

    .p-point__chrome {
      margin-top: 30px;
      max-width: 100%;
      width: 280px;
    }

    .p-point__step {
      margin-top: 40px;
    }

    .p-point__attention {
      font-size: 17px;
      margin-top: 30px;
      padding-left: calc(1em + 6px);
      position: relative;
      text-align: left;
    }

    .p-point__attention::before {
      left: 0;
      position: absolute;
      top: 0;
    }

    .u-hidden-sp {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 1149px) {
    .u-hidden-tab {
      display: none;
    }
  }

  @media not all and (max-width: 1149px) {
    .u-hidden-pc {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .u-hidden-sp {
      display: none;
    }
  }
`

export const Content: (props: ContentProps) => JSX.Element = StyledComponent

export default Content
